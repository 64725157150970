@import url("https://fonts.googleapis.com/css?family=Raleway:900&display=swap");

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-clip: padding-box;
  border: 4px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 8px;
}

*::-webkit-scrollbar-corner {
  border-radius: 6px;
}

html,
body {
  height: 100%;
}

body {
  overflow-x: hidden;
  overflow-y: hidden;
}

.main-container:has(.light-background) {
  background: var(--light-url) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.main-container:has(.light-background-transparent) {
  background: var(--light-url) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.main-container:has(.dark-background) {
  background: var(--dark-url) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.main-container:has(.dark-background-transparent) {
  background: var(--dark-url) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#root {
  height: 100%;
}

.content {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 24px 10px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.content.dark-background,
.content.dark-background-transparent {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 30px 5px, rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;
}

.mobile-content {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 15px !important;
}

.mobile-content.dark-background-transparent {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 24px 0px, rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;
}

.window-content {
  overflow: auto;
  height: 100%;
  padding: 10px;
  border-radius: 12px;
  margin: 0px 5px 5px 5px;
}

.window-content.light-background,
.window-content.dark-background {
  border-radius: 0px 12px 12px 12px;
}

.info {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 10px;
  border: 0.2px solid #ccc;
}

.taskbar {
  display: flex;
  width: -webkit-fill-available;
  position: fixed;
  bottom: 10px;
  z-index: 1;
  height: 50px;
  max-height: 50px;
  gap: 10px;
  margin-left: 10px;
  margin-right: 10px;
  transition: transform 0.3s ease-in-out;
}

.application-area {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 12px;
  gap: 10px;
  width: 100%;
  box-shadow: none !important;
}

.taskbar:hover {
  z-index: 999;
}

.avatar-content {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding-top: 60px;
}

.avatar-content>div {
  margin: 15px;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.avatar-content img {
  margin-bottom: 30px;
  max-width: 150px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.05);
}

.summary-info {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 10px;
}

.summary-text {
  margin-left: 10px;
}

.summary-text-title {
  font-family: Poppins, sans-serif;
  --tw-text-opacity: 1;
  color: rgb(68 86 108/var(--tw-text-opacity));
  font-size: 0.75rem;
}

.dark-background .summary-text-title,
.dark-background-transparent .summary-text-title {
  color: white;
}

.summary-text-content {
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.introduce-animated {
  font-family: "Anonymous Pro", monospace !important;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.introduce-text {
  width: 100%;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 40px #fff;
}

.my-name {
  font-weight: bolder;
  font-family: 'Righteous', serif;
  text-shadow: .03em .03em 0 hsla(230, 40%, 50%, 1);
  transition: all 0.25s ease-out;
}

.blink-animation {
  animation: blink-caret .75s steps(17) infinite;
}

.main-container {
  height: 100%;
  /* transition: background 0.3s ease 0s; */
}

.personal-info {
  min-width: 380px;
  max-width: 100%;
}

.btn {
  border-radius: 10px;
  position: relative;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.75);
  border: none;
  min-height: 35px;
  text-align: center;
  font-size: 16px;
  padding: 5px;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
  background-size: 300% 100%;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 10px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s;
  box-shadow: 0 0 10px 40px white;
}

.btn:active:after {
  box-shadow: 0 0 0 0 white;
  position: absolute;
  border-radius: 10px;
  left: 0;
  top: 0;
  opacity: 1;
  transition: 0s;
}

.btn:active {
  top: 1px;
}

.error-color {
  color: #fff;
  background-image: linear-gradient(to right, #eb3941, #f15e64, #e14e53, #e2373f);
  box-shadow: 0 5px 15px rgba(242, 97, 103, .4);
}

.warning-color {
  color: #fff;
  background-image: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75)
}

.default-color {
  color: #fff;
  background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}

.success-color {
  color: #fff;
  background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
  box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75)
}

.search-area {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 15px;
}

@media screen and (max-width: 250px) {

  .not-support-message {
    display: block !important;
  }

  .main-container {
    display: none;
  }
}

@media screen and (max-height: 600px) {
  .container {
    margin-bottom: 95px;
  }
}

@media screen and (max-width: 280px) {

  .summary-text-content {
    width: 50px;
  }
}

@media screen and (max-width: 320px) and (min-width: 280px) {

  .summary-text-content {
    width: 100px;
  }
}


@media screen and (max-width: 430px) and (min-width: 320px) {

  .summary-text-content {
    width: 150px;
  }
}

@media screen and (max-width: 768px) {

  .main-content {
    padding: 0;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
    height: 100vh;
  }
}

@media screen and (max-width: 1280px) {
  .main-container {
    margin-right: auto;
    margin-left: auto;
    height: auto;
    padding: 10px;
  }

  .main-settings {
    max-width: 100% !important;
    margin-bottom: 10px;
  }

  .setting-content {
    display: block !important;
  }

  body {
    overflow: auto;
    margin: 0;
    height: 100%;
  }

  .search-area,
  .tool-area {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .desktop {
    display: none !important;
  }

  .taskbar {
    display: none !important;
  }

  .color-section {
    display: none !important;
  }

}

@media screen and (max-width: 1920px) {
  .main-content {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.mobile {
  margin-bottom: 10px;
  display: none;
}

.desktop {
  display: block;
}

.light-background {
  background-color: #f1f3f4;
  color: black;
}

.light-background-transparent {
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  transition: background-color .3s ease-out;
}

.dark-background {
  background-color: rgba(34, 39, 46, 1);
  color: white;
}

.dark-background-transparent {
  background-color: rgba(34, 39, 46, 0.5);
  color: white;
  transition: background-color .3s ease-out;
}

.m-1 {
  margin: 10px;
}

.m-2 {
  margin: 20px;
}

.m-3 {
  margin: 30px;
}

.mr-4 {
  margin: 40px;
}

.m-5 {
  margin: 50px;
}

.pd-1 {
  padding: 10px;
}

.pd-2 {
  padding: 20px;
}

.pd-3 {
  padding: 30px;
}

.pd-4 {
  padding: 40px;
}

.pd-5 {
  padding: 50px;
}

.br {
  border-radius: 7.5px;
}

.br-1 {
  border-radius: 15px;
}

.br-2 {
  border-radius: 20px;
}

.br-3 {
  border-radius: 30px;
}

.br-4 {
  border-radius: 40px;
}

.br-5 {
  border-radius: 50px;
}

.blur-5 {
  backdrop-filter: blur(50px);
  transform: translateZ(0);
}

.blur-4 {
  backdrop-filter: blur(40px);
  transform: translateZ(0);
}

.blur-3 {
  backdrop-filter: blur(30px);
  transform: translateZ(0);
}

.blur-2 {
  backdrop-filter: blur(20px);
  transform: translateZ(0);
}

.blur-1 {
  backdrop-filter: blur(10px);
  transform: translateZ(0);
}

.bd-b {
  border-bottom: 0.7px solid #ccc;
}

.bd-t {
  border-top: 0.7px solid #ccc;
}

.bd-r {
  border-right: 0.7px solid #ccc;
}

.bd-l {
  border-left: 0.7px solid #ccc;
}

.not-support-message {
  display: none;
  font-weight: 900;
  text-align: center;
}

.action-bar {
  position: relative;
  min-height: 45px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease-in-out, opacity 0.15s linear 0s, visibility 0.15s linear 0s;
}

.xp-style {
  background-image: linear-gradient(to right, #0026cd, transparent 5%, transparent 95%, #0026cd), linear-gradient(#1777cf, #058ffe 2%, #058ffe 5%, #004be5 15%, #004be5 50%, #005fff 80%, #0044c5);
  /* background: linear-gradient(180deg,#0997ff,#0053ee 8%,#0050ee 40%,#06f 88%,#06f 93%,#005bff 95%,#003dd7 96%,#003dd7); */
}

.action-bar.hidden {
  opacity: 0;
  visibility: hidden;
}

.drag-area {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 35px;
  border-radius: 12px;
  margin-right: 5px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.action-buttons>.action-icon {
  text-align: center;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  min-height: 35px;
  background: none;
  border: none;
  transition: background-color 0.3s linear 0.1s;
  cursor: pointer;
}

.action-buttons>.action-icon:hover {
  background-color: #9f9f9fd6;
}

.action-buttons>.action-icon:nth-child(1) {
  border-radius: 12px 0px 0px 12px;
}

.action-buttons>.action-icon:nth-child(3) {
  border-radius: 0px 12px 12px 0px;
}

.action-buttons>.action-icon:last-child:hover {
  background-color: #ff4000d6;
}

.tab-name {
  font-weight: bold;
  border-radius: 12px 12px 0px 0px;
  min-height: 35px;
  height: 35px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  width: 200px;
  min-width: 100px;
  position: relative;
  font-size: 0.7rem;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.tab-name.blur-3 {
  border-radius: 12px;
}

.tab-name>.layout {
  height: 10px;
  position: absolute;
  top: 100%;
  width: 100%;
}

.tab-name.blur-3>.layout {
  display: none;
}

.tab-name.light-background>.layout {
  background-color: #f1f3f4;
}

.tab-name.dark-background>.layout {
  background-color: rgba(34, 39, 46, 1);
}

.input-bar {
  width: 100%;
  position: relative;
}

.input {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  border: none;
  min-height: 20px;
  max-height: 40px;
  transition: box-shadow 0.3s ease;
}

.input:focus {
  outline: none;
  box-shadow: 0 0 0 3px hsla(220, 100%, 50%, 80%);
}

.input-icon-left {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

.input-icon-right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

.taskbar>.taskbar-area {
  display: flex;
  gap: 10px;
  border-radius: 15px;
  flex-direction: row;
  align-items: center;
  max-height: 50px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.taskbar-space {
  width: 100%;
}

.application-area>.application {
  position: relative;
  height: 50px;
  width: 50px;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  gap: 10px;
  align-items: center;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, width 0.3s ease-in-out;
  border-radius: 15px;
}

.application > .app-name {
  font-weight: bold;
}

.application>img.app-icon {
  width: 40px;
  height: 40px;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out, box-shadow 0.6s ease-in-out;
}

.application-area>.application:hover {
  background-color: #bfbfbfa9;
  box-shadow: 0px 0px 0px 3px #bfbfbf;
  cursor: pointer;
}

.application-area>.application:hover>img.app-icon {
  transform: scale(1.15);
}

.main>.application:hover>img.app-icon {
  box-shadow: 0px 0px 10px 3px rgb(22 119 255);
}


.main>.application>img {
  height: 35px;
  width: 35px;
}

.main>.application {
  cursor: pointer;
  border-radius: none;
  position: relative;
  height: 50px;
  width: 50px;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  gap: 5px;
}

.search-input {
  height: 60px;
}

.application-area>.application.opened-app {
  box-shadow: 0px 0px 0px 3px rgb(22 119 255);
  background-color: rgba(40, 130, 255, 0.462);
}

.tool-area {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 12px;
  height: 40px;
  gap: 10px;
  min-width: 100px;
  margin-left: 5px;
  transition: background-color 0.2s ease-in-out 0s;
  position: relative;
}

.notification-tile {
  width: 40px;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  border-radius: 12px;
  transition: background-color 0.2s ease-in-out 0s;
  position: relative;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 10px;
  font-weight: 700;
  color: white;
  padding: 3px;
}

.notification-tile.opened {
  background-color: #cccccca3;
}

.notification-tile:hover {
  background-color: #cccccca3;
}

.tool-area:hover {
  background-color: #cccccca3;
}

.tool-area.xp-style-hover {
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}

.date-and-time:hover {
  background-color: #cccccca3;
}

.date-and-time {
  font-weight: bold;
  font-size: 12.5px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 50px;
  cursor: default;
  transition: background-color 0.2s ease-in-out 0s;
}

.app-open-animation {
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: translateY(0, 100px);
}

.window {
  opacity: 1;
  visibility: visible;
  display: flex;
  transition: background-color 0.3s ease-in-out, opacity 0.15s linear 0s, visibility 0.15s linear 0s;
}

.theme-setting {
  margin-right: 10px;
  margin-left: 10px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;
}

.theme-config-list {
  position: absolute;
  bottom: 115%;
  height: max-content;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  opacity: 0;
  width: max-content;
  padding: 10px;
  visibility: hidden;
}

.theme-config-list::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 1) transparent transparent transparent;
}

.theme-setting:hover .theme-config-list {
  opacity: 1;
  visibility: visible;
}

.switch-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
}

.switch-label {
  font-weight: bold;
  margin-right: 5px;
}

.switch-additional-label {
  font-weight: bold;
  margin-left: 5px;
}

.switch {
  border-radius: 20px;
  min-height: 26px;
  min-width: 50px;
  border: none;
  cursor: pointer;
  padding: 0;
  /* box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.05); */
  box-shadow: 0 0 5px 0.5px rgba(0, 0, 0, 0.2) inset;
  transition: background-color 0.2s ease-in-out;
  position: relative;
}

.switch:has(.switch-lever-inactive):hover {
  background-color: rgb(165, 165, 165) !important;
}

.switch:has(.switch-lever-active):hover {
  background-color: rgb(81, 153, 255) !important;
}

.switch.disabled {
  cursor: not-allowed;
}

.switch-disabled {
  width: 100%;
  height: 100%;
  background-color: #00000078;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
}

.switch:has(.switch-lever-inactive) {
  background-color: rgb(191 191 191);
}

.switch:has(.switch-lever-active) {
  background-color: rgb(22 119 255);
}

.switch-lever {
  margin: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, .2);
}

.switch-lever-active {
  margin-left: 27px;
}

.switch-lever-inactive {
  margin-left: 3px;
}

.settings {
  width: 50px;
  height: 50px;
}

.settings>img {
  width: 100%;
  height: 100%;
}

.add-more-tab {
  border-radius: 50%;
  min-width: 25px;
  min-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  transition: background-color 0.3s linear 0s;
}

.add-more-tab:hover {
  background-color: rgba(22, 119, 255, 0.548);
  cursor: pointer;
}

.list-wallpapers {
  display: flex;
  margin-top: 20px;
  align-content: center;
  align-items: center;
  justify-items: start;
  gap: 10px;
  flex-wrap: wrap;
  flex-direction: row;
}

.wallpaper-card {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.wallpaper-card:hover>img {
  box-shadow: 0px 0px 0px 3px rgba(22, 119, 255, 0.548);
}

.wallpaper-card>img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
}

.current-wallpaper {
  box-shadow: 0px 0px 0px 4px rgb(22 119 255);
  cursor: not-allowed;
}

.setting-content {
  display: flex;
  gap: 10px;
}

.main-settings {
  max-width: 500px;
}

.setting-icon {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  margin-right: 10px;
}

.setting-title-text {
  width: 100%;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.selectable {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.selectable:hover {
  background-color: #4096ff50;
}

.current-setting {
  background-color: #1677ffc5;
}

.setting-icon>img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.setting-section {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 10px;
  position: relative;
}

.setting-detail-content {
  width: 100%;
}

.color-section {
  border-radius: 10px;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 0.8px solid #ccc;
}

.dark-background .color-section,
.dark-background-transparent .color-section {
  box-shadow: inset 0 2px 4px 0 rgba(255, 255, 255, 0.05);
}

.color-section>.text {
  width: 100%;
  text-align: center;
}

.color-select-container {
  min-height: 40px;
  width: 100%;
  position: relative;
}

.disabled-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #b3b3b352;
  z-index: 22;
  cursor: not-allowed;
}

.color-selection {
  margin-top: 10px;
  display: flex;
  width: fit-content;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.color-panel {
  z-index: 1;
  min-height: 40px;
  cursor: pointer;
  transition: width 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.3s linear;
  width: 40px;
}

.color-panel:hover {
  transform: scale(1.1);
}

.current-color-panel {
  box-shadow: 0px 0px 0px 4px rgba(22, 255, 88, 0.711);
}

.color-picker {
  z-index: 1;
  min-height: 40px;
  cursor: pointer;
  transition: width 0.3s ease-in-out, box-shadow 0.3s ease-in-out, transform 0.3s linear;
  width: 45px;
  height: 45px;
  min-width: 40px;
  border: 2px solid #ccc;
}

.color-picker:hover {
  transform: scale(1.1);
}

.color-picker::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-picker::-webkit-color-swatch {
  border: none;
  border-radius: 12px;
}

.setting-switch {
  width: 100%;
  position: relative;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-clip: padding-box;
  border: 4px solid transparent;
}

*::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

.modal-header {
  max-height: 50px;
  min-height: 35px;
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 5px 15px -10px rgba(0, 0, 0, 0.45);
}

.modal-title {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 20px;
}

.modal-body {
  height: 100%;
  padding: 10px;
}

.modal-footer {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-height: 50px;
  min-height: 35px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 0 0 20px 20px;
  flex-direction: row-reverse;
  box-shadow: 0px -5px 15px -10px rgba(0, 0, 0, 0.45);
}

@keyframes unfoldIn {
  0% {
    transform: scaleY(.005) scaleX(0);
  }

  50% {
    transform: scaleY(.005) scaleX(1);
  }

  100% {
    transform: scaleY(1) scaleX(1);
  }
}

@keyframes unfoldOut {
  0% {
    transform: scaleY(1) scaleX(1);
  }

  50% {
    transform: scaleY(.005) scaleX(1);
  }

  100% {
    transform: scaleY(.005) scaleX(0);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.modal-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 19;
  transition: visibility 1.5s ease-in-out;
}

.open {
  transform: scaleY(.01) scaleX(0);
  animation: unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  visibility: visible;
}

.close {
  transform: scale(1);
  animation: unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  visibility: hidden;
}

.open .modal {
  transform: scale(0);
  animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  visibility: visible;
}

.close .modal {
  animation: zoomOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  visibility: hidden;
}

.modal-background {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.modal {
  border-radius: 20px;
  box-shadow: 0 22px 70px 4px rgba(0, 0, 0, 0.56);
  z-index: 20;
  max-height: 600px;
  transition: visibility 1s ease-in-out;
}

.modal-timing {
  font-weight: bolder;
  border-radius: 8px;
  color: #FA541C;
  background-color: rgb(253, 227, 207, 0.5);
  width: 100%;
  max-width: fit-content;
  min-width: 25px;
  height: 100%;
  padding: 2.5px;
}

.profile {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin: auto;
  width: 100%;
  justify-content: center;
}

.left-profile {
  width: 100%;
  max-width: 800px;
}

.right-profile {
  max-width: 400px;
  width: 100%;
  height: 100%;
}

.skills {
  width: 100%;
}

.skill {
  min-height: 50px;
}

.skill>.skill-name {
  min-width: 100px;
  margin-bottom: 5px;
}

.level {
  width: 100%;
  height: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #0871c6;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.level-progress-75 {
  width: 75%;
}

.level-progress-90 {
  width: 90%;
}

.skill-title {
  margin-bottom: 10px;
}

.skill-title>span {
  font-family: Poppins, sans-serif;
  font-size: 1.1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  margin-left: 10px;
}

h1.summary-title {
  font-size: 15.5pt;
  font-family: "Calibri", sans-serif;
  display: flex;
  align-items: center;
  gap: 5px;
}

.project {
  margin-left: 20px;
  font-size: 13px;
}

.project-date {
  font-size: 9.0pt;
  font-family: "Calibri", sans-serif;
}

.project-name {
  font-size: 11.0pt;
  font-family: "Calibri", sans-serif;
  font-weight: bold;
}

.my-cv {
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.timer {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-bar-link {
  cursor: pointer;
}

.action-bar-link:hover {
  color: #0871c6;
}

.starting-screen {
  position: absolute;
  background-color: #000000;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  gap: 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  transition: transform 1s ease-in-out;
}

.started {
  transform: translateY(-100%);
}

.starting-logo {
  width: 200px;
  height: 200px;
}

.starting-logo>img {
  width: 100%;
  height: 100%;
}

.loading-spin {
  visibility: visible;
  opacity: 1;
}

.loading-spin.hidden {
  visibility: hidden;
  opacity: 0;
}

.startup-loading {
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.startup-text {
  font-weight: bold;
  font-size: 20px;
  animation: appear 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@keyframes appear {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

.notifications {
  position: absolute;
  bottom: 70px;
  right: 10px;
  width: 300px;
  height: 500px;
  z-index: 19;
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.notifications.opened {
  visibility: visible;
  opacity: 1;
  animation: openQuickSetting 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.notifications.closed {
  visibility: hidden;
  opacity: 0;
  animation: closeQuickSetting 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.notification-header {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
}

.notification-title {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}

.notification-time {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.close-notification-icon {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  transition: background-color 0.3s linear;
}

.close-notification-icon:hover {
  background-color: #f0555c;
}

.time-content {
  font-size: 14px;
  font-weight: bold;
  width: 100%;
}

.notification-actions {
  display: flex;
  width: 100px;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.notification-action {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s linear;
}

.notification-action:hover {
  background-color: #cccccccd !important;
}

.disturb-tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 140%;
  padding: 10px;
  min-width: 100px;
  right: -60px;
  text-align: center;
  transition: all 0.3s linear 0s;
}

.clear-all-tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 140%;
  padding: 10px;
  min-width: 100px;
  right: -10px;
  text-align: center;
  transition: all 0.3s linear 0s;
}

.notification-action:hover>.disturb-tooltip {
  visibility: visible;
  opacity: 1;
}

.notification-action:hover>.clear-all-tooltip {
  visibility: visible;
  opacity: 1;
}

.notification-action.active {
  background-color: rgb(22 119 255) !important;
}

.notification-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.notification {
  width: 275px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
  max-height: 500px;
  transition: max-height 0.3s ease-out;
  border: 0.3px solid #cccccccd;
}

.notification.closed {
  max-height: 0;
  opacity: 0;
  padding: 0;
  margin: 0;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-in-out, padding 0.3s ease-in-out, margin 0.3s ease-in-out;
  overflow: hidden;
}

.notification-description {
  margin-top: 5px;
  font-size: 14px;
}

.quick-settings {
  position: absolute;
  bottom: 70px;
  right: 10px;
  width: 300px;
  height: 400px;
  z-index: 19;
  padding: 10px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.quick-settings.opened {
  visibility: visible;
  opacity: 1;
  animation: openQuickSetting 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.quick-settings.closed {
  visibility: hidden;
  opacity: 0;
  animation: closeQuickSetting 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.tool-area.opened {
  background-color: #cccccca3;
}

@keyframes closeNotification {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
    opacity: 0;
    display: none;
  }
}

@keyframes openQuickSetting {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes closeQuickSetting {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

.quick-setting-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  padding: 20px;
}

.quick-setting-tile {
  width: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.quick-setting-icon {
  border-radius: 50%;
  padding: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 0.5px solid #ccc;
  transition: background-color 0.3s ease-in-out;
}

.quick-setting-icon.turned-on {
  background-color: rgb(22 119 255) !important;
}

.light-background.quick-setting-icon {
  background-color: #ffffff;
}

.dark-background.quick-setting-icon {
  background-color: #3c3c3c;
}

.quick-setting-icon>svg {
  transition: transform 0.3s ease-in-out;
}

.quick-setting-icon:hover>svg {
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
}

.quick-setting-name {
  font-size: 9px;
  font-weight: 600;
}

.volumn-and-light {
  padding: 20px;
  width: 260px;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.volumn {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
}

.light {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
}

.slider {
  width: 100%;
  display: flex;
}

.progress-bar {
  height: 5px;
  background-color: #777777;
  width: 100%;
  border-radius: 2px;
  position: relative;
}

.quick-action-area {
  height: 40px;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.current-user {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.user-icon {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8E2DE2;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4A00E0, #8E2DE2);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.user-name {
  font-weight: bolder;
  font-size: 12px;
}

.quick-action-items {
  display: flex;
  gap: 5px;
}

.quick-action-icon {
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  cursor: pointer;
}

.time-and-battery {
  width: 100%;
  display: flex;
  height: 30px;
}

.battery-status {
  display: flex;
  gap: 5px;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
}

.battery-percents {
  font-weight: bold;
  font-size: 14px;
}

.battery-icon {
  height: 100%;
  display: flex;
  align-items: center;
}

.time-calendar {
  display: flex;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  gap: 10px;
  align-items: center;
}

.point {
  height: 100%;
  display: flex;
  align-items: center;
}

.night-light {
  width: 100%;
  height: 100%;
  background-color: #4750189b;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
  transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.brightness {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  pointer-events: none;
}

.night-light.off {
  visibility: hidden;
  opacity: 0;
}

.night-light.on {
  visibility: visible;
  opacity: 1;
}

.window-new-feeds {
  z-index: 18;
  position: absolute;
  bottom: 70px;
  left: 10px;
  width: 600px;
  height: 600px;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, opacity 0.3s ease-in-out, height 0.3s ease-in-out;
}

.window-new-feeds.opened {
  height: 600px;
  visibility: visible;
  opacity: 1;
}

.window-new-feeds.closed {
  height: 0;
  padding: 0;
  visibility: hidden;
  opacity: 0;
}

.new-feeds {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-feed-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
}

.all-apps {
  width: 100%;
  font-weight: bold;
  font-size: 12px;
}

.back-icon {
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.windows-xp-tab {
  position: relative;
  height: 40px;
  width: 100%;
  max-width: 200px;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  gap: 10px;
  align-items: center;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, width 0.3s ease-in-out;
  border-radius: 10px;
  cursor: pointer;
  background-color: #3c81f3;
  box-shadow: inset -1px 0 rgba(0,0,0,.3),inset 1px 1px 1px hsla(0,0%,100%,.2);
}

.windows-xp-tab > .app-name {
  font-weight: bold;
  color: white;
  font-size: 0.7rem;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.windows-xp-tab>img.app-icon {
  width: 33px;
  height: 33px;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out, box-shadow 0.6s ease-in-out;
}

.windows-xp-tab:hover {
  background-color: #00379eea !important;
}

.windows-xp-tab.opened-app {
  background-color: #0044c5;
}

.language-selection-container {
  border-radius: 15px;
}

.dropdown-list {
  position: relative;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  color: #000000;
}

.list-options {
  position: absolute;
  top: 110%;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  height: max-content;
  display: none;
}

.selected-value {
  width: 100%;
}

.list-options.opened {
  display: block;
}

.option {
  min-height: 30px;
  display: flex;
  align-items: center;
  padding: 2px;
}

.option:first-child {
  border-radius: 10px 10px 0px 0px;
}

.option:last-child {
  border-radius: 0px 0px 10px 10px;
}

.option:hover {
  background-color: #ccc;
}

.option.selected {
  background-color: #e6f4ff;
}

.window-resize-top-cursor {
  cursor: n-resize !important;
}

.window-resize-right-cursor {
  cursor: e-resize !important;
}

.window-resize-bottom-cursor {
  cursor: s-resize !important;
}

.window-resize-left-cursor {
  cursor: w-resize !important;
}

.weather-area {
  width: max-content;
}

.weather {
  position: relative;
  height: 50px;
  min-width: 100px;
  border-radius: 15px;
  width: 200px;
}

.weather-component {
  display: flex;
  align-items: center;
  position: relative;
  gap: 5px;
}

.weather-icon {
  width: 50px;
  height: 50px;
  border-radius: 15px;
}

.weather-icon > img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.weather-status {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: bold;
}