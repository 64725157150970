.spinner {
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  padding: 3px;
  overflow: visible;
}

.spinner>circle {
  fill: none;
  stroke: currentColor;
  cx: 50%;
  cy: 50%;
  r: 50%;
  stroke-width: 6px;
  stroke-linecap: round;
  transform-origin: center;
}